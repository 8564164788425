import { createAcl, defineAclRules } from "vue-simple-acl";
import router from "@/router";
import { useLoginStore } from '@/stores/loginStore';
import { ModulesEnum } from "@/enums/ModulesEnum";
import { getGenericModulePermissionsList } from "@/composables/usePermissions";
import { PermissionsEnum } from "@/enums/PermissionsEnum";
import useUserSession from '@/composables/useUserSession';

const {
    getRefreshToken,
    getIsAuthenticated,
    isAccessTokenExpired,
    isTwoFactorVerified
} = useUserSession();

const getUser = async () => {
    const loginStore = useLoginStore();
    if (getRefreshToken() === null || !isTwoFactorVerified()) {
        return {
            permissions: [],
            user: {},
        };
    }

    if (!getIsAuthenticated() || isAccessTokenExpired()) {
        await loginStore.refreshCredentials();
    } else {
        loginStore.startRefreshTokenTimer();
    }
    await loginStore.fetchMe();
    return loginStore.getMe;
}

const rules = () => defineAclRules((setRule) => {
    const allRules = [
        ...Object.values(ModulesEnum)
            .map((value) => getGenericModulePermissionsList(value))
            .flat(),
        ...Object.values(PermissionsEnum)
    ];

    for (const rule of [...new Set(allRules)]) {
        setRule(rule, (us) => {
            return us?.permissions?.includes(rule) ?? false;
        });
    }
});

const simpleAcl = createAcl({
    user: getUser,
    rules,
    router,
    onDeniedRoute: '/unauthorized',
});

export default simpleAcl;
