import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from '@/composables/usePermissions';
import { ModulesEnum } from '@/enums/ModulesEnum';

const ClientsRoutes = [
    {
        path: '/clients',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'ClientsModule.crm',
            icon: 'mdi-handshake',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: 'inquiries/:id(\\d+|new)?',
                name: RouteNamesEnum.Inquiries,
                component: () => import('../views/clients-module/InquiriesView.vue'),
                meta: {
                    title: 'ClientsModule.inquiries',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Inquiries),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'eq', v: null, visible: false },
                        { f: 'order_accepted', o: 'eq', v: false, visible: false },
                    ],
                },
            },
            {
                path: 'inquiries/archive/:id(\\d+|new)?',
                name: RouteNamesEnum.InquiriesArchive,
                component: () => import('../views/clients-module/InquiriesView.vue'),
                meta: {
                    title: 'ClientsModule.inquiries-archive',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Inquiries),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'neq', v: null, visible: false },
                    ],
                },
            },
            {
                path: 'inquiry/:id/calculation/:calculationId(\\d+|new)?',
                name: RouteNamesEnum.InquiryCalculations,
                component: () => import('../views/clients-module/InquiryCalculationsView.vue'),
                meta: {
                    title: 'ClientsModule.inquiry-calculation',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Inquiries),
                    showInMenu: false,
                },
            },
            {
                path: 'inquiry/:id/calculation/:calculationId/proposal/:proposalId(\\d+|new)?',
                name: RouteNamesEnum.InquiryProposals,
                component: () => import('../views/clients-module/InquiryProposalsView.vue'),
                meta: {
                    title: 'ClientsModule.inquiry-proposal',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Inquiries),
                    showInMenu: false,
                },
            },
            {
                path: ':id(\\d+|new)?',
                name: RouteNamesEnum.Clients,
                component: () => import('../views/clients-module/ClientsView.vue'),
                meta: {
                    title: 'ClientsModule.name',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Clients),
                    showInMenu: true,
                },
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'resources/:id(\\d+|new)?',
                        name: RouteNamesEnum.InquiryResources,
                        component: () => import('../views/clients-module/InquiryResourcesView.vue'),
                        meta: {
                            title: 'ClientsModule.resources',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.InquiryResources),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'inquiry-registration-number-series/:id(\\d+|new)?',
                        name: RouteNamesEnum.InquiryRegistrationNumberSeries,
                        component: () => import('../views/settings-module/InquiryRegistrationNumberSeriesView.vue'),
                        meta: {
                            title: 'SettingsModule.inquiry-registration-number-series',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.InquiryRegistrationsNumberSeries),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'inquiry-resource/:id(\\d+|new)?',
                        name: RouteNamesEnum.InquiryResourceGroups,
                        component: () => import('../views/settings-module/InquiryResourceGroupsView.vue'),
                        meta: {
                            title: 'SettingsModule.inquiry-resource-groups',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.InquiryResources),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'inquiry-calculation-duties/:id(\\d+|new)?',
                        name: RouteNamesEnum.InquiryCalculationDuties,
                        component: () => import('../views/settings-module/InquiryCalculationDutiesView.vue'),
                        meta: {
                            title: 'SettingsModule.inquiry-calculation-duties',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.InquiryCalculationDuties),
                            showInMenu: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default ClientsRoutes;
