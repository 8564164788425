import { defineStore } from 'pinia';
import axiosInstance, { useAxios } from '@/axios';
import { useGlobalComponentsStore } from '@/stores/global';
import { i18n } from '@/main';
import { NotificationClass } from '@/enums/NotificationClassEnum';
import useUserSession from '@/composables/useUserSession';

export type UserSession = {
    isAuthenticated: boolean;
    accessToken: string | null;
    accessTokenExpiration: string | null;
    refreshToken: string | null;
    refreshTokenTimeout: ReturnType<typeof setTimeout> | undefined;
    userId: number | null;
    language: string;
    sessionId: number | null;
    twoFactorVerified: boolean;
};

export type MeType = {
    permissions?: string[];
    user?: {
        created_at: string;
        uprated_at: string;
        email: string;
        id: number;
        is_active: boolean;
        first_name: string;
        last_name: string;
        username: string;
        phone: string;
        phone_country_id: string;
    }
}

const {
    savePartialUserSession,
    getAccessTokenExpiration,
    getRefreshTokenTimeout,
    getSessionId,
    getRefreshToken
} = useUserSession();

export const useLoginStore = defineStore('loginStore', {
    state: (): { me: MeType, fetchDebounce: { [key: string]: Promise<any> } } => {
        return {
            me: {},
            fetchDebounce: {},
        };
    },
    getters: {
        getUser: (state) => state.me.user,
        getUserFullName: (state) => `${state.me.user?.first_name} ${state.me.user?.last_name}`,
        getUserEmail: (state) => `${state.me.user?.email}`,
        getUserPhone: (state) => `${state.me.user?.phone ?? ''}`,
        getUserInitials: (state) => {
            const firstName = state.me.user?.first_name;
            const lastName = state.me.user?.last_name;
            return `${firstName?.charAt(0) ?? ''}${lastName?.charAt(0) ?? ''}`;
        },
        getUserId: (state) => state?.me?.user?.id,
        getMe: (state) => state.me,
    },
    actions: {
        startRefreshTokenTimer(): void {
            const expiration = getAccessTokenExpiration();
            if (expiration === null) {
                return;
            }
            const date: Date = new Date(expiration);
            //refreshnuti minutu pred expiraci
            const timeout: number = date.getTime() - Date.now() - 60 * 1000;
            if (getRefreshTokenTimeout() !== undefined) {
                clearTimeout(getRefreshTokenTimeout());
            }
            savePartialUserSession({
                refreshTokenTimeout: setTimeout(
                    this.refreshCredentials,
                    timeout
                )
            })
        },
        async refreshCredentials(afterRefresh: boolean = false): Promise<void> {
            if (this.fetchDebounce.refresh !== undefined) {
                // prevent multiple requests
                return this.fetchDebounce.refresh;
            }

            const globalStore = useGlobalComponentsStore();
            if (afterRefresh) {
                globalStore.showLoading = true;
            }

            this.fetchDebounce.refresh = axiosInstance
                .post('api/v0/auth/user-tokens', {
                    refresh_token: getRefreshToken(),
                })
                .then((response) => {
                    this.setCredentials(
                        response.data.access_token,
                        response.data.refresh_token,
                        response.data.access_expiration_at,
                        response.data.user_id,
                        response.data.id,
                        response.data.two_factor_verified,
                    );
                    if (afterRefresh) {
                        globalStore.showLoading = false;
                    }
                })
                .catch((error) => {
                    if (afterRefresh) {
                        globalStore.showLoading = false;
                    }
                    //API error
                    try {
                        globalStore.setSnackbarTextAndClass(
                            error.response.data.message,
                            NotificationClass.ERROR
                        );
                    } catch (e) {
                        //Axios error
                        globalStore.setSnackbarTextAndClass(
                            error.message,
                            NotificationClass.ERROR
                        );
                    }
                    this.logout();
                })
                .finally(() => {
                    delete this.fetchDebounce.refresh;
                });

            await this.fetchDebounce.refresh;
        },
        stopRefreshTokenTimer(): void {
            clearTimeout(getRefreshTokenTimeout());
        },
        async logout(redirect: boolean = true): Promise<void> {
            const globalStore = useGlobalComponentsStore();

            //TODO: vymazat filtry
            this.stopRefreshTokenTimer();
            globalStore.showLoading = true;
            const sessionId = getSessionId();
            if (sessionId !== null) {
                try {
                    await useAxios().delete(
                        `api/v0/auth/user-sessions/${sessionId}`
                    );
                    globalStore.setSnackbarTextAndClass(
                        i18n.global.t('NotificationMessages.logout'),
                        NotificationClass.SUCCESS
                    );
                } catch (error: any) {
                    globalStore.setSnackbarTextAndClass(
                        error.message,
                        NotificationClass.ERROR
                    );
                }
            }

            if (redirect) {
                window.location.href = window.location.origin
            }

            globalStore.showLoading = false;
        },
        setCredentials(
            accessToken: string,
            refreshToken: string,
            accessTokenExpiration: string,
            userId: number,
            sessionId: number,
            twoFactorVerified: boolean = false
        ): void {
            savePartialUserSession( {
                accessToken,
                refreshToken,
                accessTokenExpiration,
                isAuthenticated: twoFactorVerified,
                userId,
                sessionId,
                twoFactorVerified,
            });
            if (twoFactorVerified) {
                this.startRefreshTokenTimer();
            }
        },
        setLanguage(language: string): void {
            savePartialUserSession({ language });
            i18n.global.locale.value = language as 'cs' | 'en';
        },
        async fetchMe() {
            try {
                if (this.fetchDebounce.me !== undefined) {
                    return this.fetchDebounce.me;
                }

                this.fetchDebounce.me = useAxios().get(
                    `/api/v0/auth/me`
                );
                this.me = (await this.fetchDebounce.me).data;
                const promise = this.fetchDebounce.me;
                delete this.fetchDebounce.me;
                return promise;
            } catch (e: any) {
                console.error(e);
                savePartialUserSession({
                    refreshToken: null,
                    refreshTokenTimeout: undefined,
                    isAuthenticated: false,
                    twoFactorVerified: false,
                });
                window.location.href = window.location.origin
            }
        },
    },
});
